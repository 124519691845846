











































































































































































import Vue from "vue";
import { mapState } from "vuex";
import { dispatch, ListQuery } from "@/store";
import { OrderStatus, Currencies } from "@/enum";

export default Vue.extend({
  data() {
    return {
      dispatch,
      loading: false,
      form: this.$form.createForm(this),
      platformList: [],
      producerList: [],
      fields: [
        {
          title: "中文名",
          dataIndex: "name_cn",
          rules: [
            {
              required: true,
              whitespace: true,
              message: "请输入中文名",
              trigger: "blur"
            },
            {
              max: 40,
              message: "请输入少于 40 个字符",
              trigger: "blur"
            }
          ]
        },
        {
          title: "英文名",
          dataIndex: "name_en",
          rules: [
            {
              required: true,
              whitespace: true,
              message: "请输入英文名",
              trigger: "blur"
            },
            {
              max: 40,
              message: "请输入少于 40 个字符",
              trigger: "blur"
            }
          ]
        }
      ],
      columns: [
        {
          title: "发售平台",
          dataIndex: "platform_info",
          scopedSlots: { customRender: "name" }
        },
        {
          title: "制造商",
          dataIndex: "producer_info",
          scopedSlots: { customRender: "name" }
        },
        {
          title: "平台订单号",
          dataIndex: "order_no"
        },
        {
          title: "商品订单号",
          dataIndex: "sub_order_no"
        },
        {
          title: "商户订单号",
          dataIndex: "transaction_id"
        },
        {
          title: "客户邮箱",
          dataIndex: "email"
        },
        {
          title: "客户ID",
          dataIndex: "user_id"
        },
        {
          title: "收件人姓名",
          dataIndex: "address_consignee"
        },
        {
          title: "手机号",
          dataIndex: "address_phone"
        },
        {
          title: "国家",
          dataIndex: "address_country"
        },
        {
          title: "省",
          dataIndex: "address_province"
        },
        {
          title: "市",
          dataIndex: "address_city"
        },
        {
          title: "区",
          dataIndex: "address_district"
        },
        {
          title: "地址",
          dataIndex: "address_detail"
        },
        {
          title: "商品名称",
          dataIndex: "sku_name"
        },
        {
          title: "商品编码",
          dataIndex: "goods_code"
        },
        {
          title: "物品名称",
          dataIndex: "goods_name"
        },
        {
          title: "货币类型",
          dataIndex: "currency",
          customRender: (text: string) => (Currencies as any)[text]
        },
        {
          title: "订单含税单价",
          dataIndex: "amount"
        },
        {
          title: "数量",
          dataIndex: "number"
        },
        {
          title: "下订单日期",
          dataIndex: "purchase_time",
          scopedSlots: { customRender: "time" }
        },
        {
          title: "付款时间",
          dataIndex: "payment_time",
          scopedSlots: { customRender: "time" }
        },
        {
          title: "订单状态",
          dataIndex: "status",
          customRender: (text: string) => (OrderStatus as any)[text]
        },
        {
          title: "订单含税总金额",
          dataIndex: "total_amount"
        },
        {
          title: "订单支付总金额",
          dataIndex: "aggregate_amount"
        },
        {
          title: "收款渠道",
          dataIndex: "payment_method"
        },
        {
          title: "退款时间",
          dataIndex: "refund_time",
          scopedSlots: { customRender: "time" }
        },
        {
          title: "商品订单退款中金额",
          dataIndex: "refunding_amount"
        },
        {
          title: "商品订单已退款金额",
          dataIndex: "refunded_amount"
        },
        {
          title: "平台订单退款中总金额",
          dataIndex: "aggregate_refunding_amount"
        },
        {
          title: "平台订单退款总金额",
          dataIndex: "aggregate_refund_amount"
        },
        {
          title: "发货时间",
          dataIndex: "express_time",
          scopedSlots: { customRender: "time" }
        },
        {
          title: "物流商名称",
          dataIndex: "express_supplier"
        },
        {
          title: "物流号",
          dataIndex: "express_no"
        },
        {
          title: "防伪码",
          dataIndex: "security_code"
        },
        {
          title: "SN码",
          dataIndex: "sn_code"
        }
      ]
    };
  },
  computed: {
    ...mapState("warehouse", {
      list: "list",
      query: "query",
      count: "count"
    })
  },
  methods: {
    getList(query: ListQuery) {
      this.loading = true;
      dispatch
        .warehouseGetList(query)
        .then(() => {
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    },
    onFilterChange() {
      const filter = this.form.getFieldsValue();
      this.getList({
        ...this.query,
        ...filter,
        page_number: 1
      });
    },
    onReset() {
      this.form.resetFields();
      const filter = this.form.getFieldsValue();
      this.getList({
        ...this.query,
        ...filter,
        page_number: 1
      });
    },
    onTableChange(page: any, filters: any, sorter: any) {
      let ordering =
        sorter.order === "ascend" ? "-" + (sorter.columnKey || "") : "";
      ordering = sorter.order === "descend" ? sorter.columnKey || "" : ordering;
      this.getList({
        ...this.query,
        page_number: page.current,
        page_size: page.pageSize,
        ordering: ordering || this.query.ordering,
        status: (filters.status && filters.status[0]) || this.query.status
      });
    }
  },
  mounted() {
    this.getList(this.query);
    dispatch.warehouseGetPlatformList().then(res => {
      this.platformList = res.results;
    });
    dispatch.warehouseGetProducerList().then(res => {
      this.producerList = res.results;
    });
  }
});
